<template>
    <div class="builder-top-bar">
        <div class="builder-top-bar__container">
            <div class="builder-top-bar__logo">
                <router-link :to="{ name: 'Dashboard' }" :title="$t('builder.topbar.backToAdmin')">
                    <img :src="require(`@/assets/images/${$brand}-logo.svg`)" :alt="$brand" />
                </router-link>
            </div>
            <div class="builder-top-bar__instance-select">
                <instancesSelect></instancesSelect>
            </div>
            <div class="builder-top-bar__separator"></div>
            <Button @click="$router.go(-1)" icon="arrow_back_ios" type="large-text">
                {{ $t('builder.topbar.back') }}
            </Button>
            <div class="builder-top-bar__page-switcher">
                <page-switcher />
            </div>
            <div class="builder-top-bar__lang">
                <a @click="changeLocale('ru')" class="language-button">
                    <img class="language-button__image" src="@/assets/images/flags/ru.svg" alt="ru" />
                </a>
                <a @click="changeLocale('en')" class="language-button">
                    <img class="language-button__image" src="@/assets/images/flags/gb.svg" alt="eng" />
                </a>
            </div>
            <screen-switcher />
            <div class="builder-top-bar__separator"></div>
            <undo-redo />
            <div class="builder-top-bar__separator"></div>
            <div class="builder-top-bar__right">
                <save-btn @create:page="$emit('create:page')" @update:page="$emit('update:page')" />
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/common/Button.vue';
import instancesSelect from '@/components/user/instancesSelect.vue';

export default {
    name: 'TopBar',
    components: {
        PageSwitcher: () => import('@/components/builder/topbar/page-switcher'),
        ScreenSwitcher: () => import('@/components/builder/topbar/ScreenSwitcher'),
        UndoRedo: () => import('@/components/builder/topbar/UndoRedo'),
        SaveBtn: () => import('@/components/builder/topbar/SaveBtn'),
        Button,
        instancesSelect,
    },
    methods: {
        changeLocale(locale) {
            this.$i18n.locale = locale;
            localStorage.setItem('locale', locale);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
.builder-top-bar {
    position: relative;
    z-index: 2;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.17) !important;
    height: 64px;

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 30px 5px 20px;
        gap: 30px;
    }

    &__logo {
        display: flex;
        align-items: center;
        width: 200px;
        height: 56px;

        img {
            display: block;
            max-width: 200px;
            max-height: 40px;
        }
    }
    &__page-switcher {
        flex: 1;
        max-width: 400px;
        margin: 0 auto;
    }
    &__lang {
        display: flex;
    }

    &__right {
        display: flex;
    }

    &__separator {
        height: 56px;
        border-right: 1px solid rgba(0, 0, 0, 0.12);
    }
}

.language-button {
    margin: 5px;
    &__image {
        width: 20px;
        height: 20px;
    }
}
</style>
